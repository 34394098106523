.fb-popup h2 {
    border-bottom: 2px solid #4d8c40;
    padding-bottom: 0.5rem;
}

.fb-popup em {
    font-weight: 700;
    color: #4d8c40;
}

.fb-popup h4 {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.85rem;
    font-weight: 300;
}

.mapbox-gl-draw_ctrl-draw-btn.selected {
    background-color: #9e9e9e;
    border-radius: 4px;
}

